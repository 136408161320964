  #Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
  }
  
  #page-logo a {
    padding: 0.5rem;
    font-family: "Oleo Script", sans-serif;
    color: rgb(6, 214, 251);
    font-size: 3rem;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
  }
  .menu-btn{
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    display: none;
  }
  
  .menu-btn span{
    width: 100%;
    height: 3px;
    background-color: black;
    ;
  }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
  
  header li {
    margin: 10px;
    text-align: center;
    
  }
  
  header li a {
    text-decoration: none;
    color:#52535B;
    font-size: 16px;
    padding: .3rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }
  
  header li a:hover {
    color: rgb(77, 77, 77);
    background-color:  #D4E0E0;
    ;
  }
  #side-drawer{
    width: 100%;
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }
  
  #side-drawer:target{
    display: block;
    z-index: 9999;
  }
  
  #side-drawer ul{
    list-style: none;
    margin: 0;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #side-drawer li{
    padding: .5rem;
  }
  #side-drawer a{
    color: black;
    font-size: 2rem;
    text-decoration: none;
  }
  #side-drawer header{
   height: 5rem;
   display: flex;
   justify-content: space-between;
   background-color: #D4E0E0;
   padding: 0 5%;
  }
  
  

  
  @media (max-width: 48rem) {
    header nav{
      display: none;
    }
    .menu-btn{
      display: flex;
    }
    #page-logo a{
      font-size: 1.5rem;
    }
    
  }
  
  