
  /* Estilos para el footer */
  .footer {
    flex-shrink: 0;
    width: 100%;
    font-family: Roboto;
    background-color: #D4E0E0;
    padding:0 30px 0 30px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  
  .footer-logo {
    width: 291.28px;
    height: 71.94px;
    margin: 0;
    flex-shrink: 0;
  }
  .footer-right {
    display: flex;
    width: 602.5px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    padding: 10px;
  }
  
  .social-icon {
    display: inline-block;
    margin: 0;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  
  .footer-divider {
    border: 1px solid #ccc;
    margin: 10px 0;
  }
  
  .footer-bottom {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0px; 
    flex-wrap: wrap; 
  }
  
  .footer-text {
    width: auto;
    height: 24px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    padding: 5px 15px;
    line-height: 24px; 
  }
  
  .footer-text-left {
    color: #52545B;
    padding: 5px 10px;
  }
  
  .footer-text-right {
    color: #52545B;
    font-weight: 600;
    padding: 5px 15px;
    text-decoration: underline;
  }
  
  .footer-text-right:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    .footer-left{
      width: 400px;
    }
    .footer-logo{
      object-fit: fill;
      width: 100%;
      height: auto;
    }
    .footer-text-right{
      margin: auto;
    }
    .footer-text-left{
      margin: auto;
    }
  }