.App {
  text-align: center;
}

.FormTramite{
  width: 80%;
  margin: auto;
  margin-top: 30px;
  text-align: left!important;
  border-radius: 10px;
  box-shadow: 10px 5px 5px rgb(80, 80, 80);
  margin-bottom: 100px;
}
.FormTitle{
  flex-grow: 1;
  text-align: center;
  font-size: xx-large !important ;
  color:#6e6e6e;
}
.FormContent{
  padding: 5px;
 
}
.ViewTramite{
  width: 40%;
  margin: 2rem auto;
  border-radius: 10px;
  border-color: transparent;
  border-style: solid;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}
.FormActa{
  text-align: left!important;
  margin: auto;
  width: 65%;
}
.ViewActa{
  margin: 2rem auto;
  width: 60%;
  text-align: center;
  border-radius: 10px;
  border-color: transparent;
  border-style: solid;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
}
@media(max-width: 768px){
  .FormTramite{
    width: 100%;
  }
  .FormActa{
    width:  100%;
  }
  .FormActa label{
    font-size: 14px!important;
  }
  .ViewActa{
    width: 95%;
  }
  .ViewTramite{
    width: 95%;
  }
}
